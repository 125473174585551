import swiper from 'swiper';

$('.js-slider-content').each(function(i, v){
  var $that = $(this);

  var imageNav = new swiper ($that.find('.js-slider-nav'), {
    // Optional parameters
    // direction: 'vertical',
    loop: false,
    // slidesPerView: 5,
    speed:150,
    autoplay: false,
    spaceBetween: 8,
    // centeredSlides: true,
    observer: true,
    // observeParents: true,
    preloadImages: false,
    // noSwiping: true,
    slidesPerView: "auto",
    autoHeight: true,
    allowTouchMove: false,
    // watchSlidesVisibility: true,
    // watchSlidesProgress: true,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2
    },
    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    breakpoints: {
      768: {
        direction: 'horizontal',
        allowTouchMove: true,
        spaceBetween: 7
      }
    }
  });

  var imageMain = new swiper ($that.find('.js-slider-main'), {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    effect: "fade",
    // slidesPerView: 5,
    speed:150,
    autoplay: false,
    spaceBetween: 0,
    centeredSlides: true,
    observer: true,
    // observeParents: true,
    preloadImages: false,
    // noSwiping: true,
    slidesPerView: "auto",
    autoHeight: true,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2
    },
    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    thumbs: {
      // swiper: imageNav
    },
    on: {
      init: function () {
        $that.find(".js-slider-nav .swiper-slide").eq(this.activeIndex).addClass("is-active");
        // underLine(this.activeIndex);
      },
    }
  });

  imageMain.controller.control = imageNav; //メインスライダーとナビの紐付け
  // imageNav.controller.control = imageMain; //ナビとメインスライダーの紐付け

  $that.find('.js-slider-nav .swiper-slide').on('click', function(){
    var $this = $(this);
    var index = imageNav.clickedIndex;
    imageMain.slideTo(index);
    // imageNav.slideTo(index);
    // console.log(index);
    // console.log(imageNav.progress);
    // console.log(imageNav.translate);
    // if(index <= 2) {
    //   imageNav.setTranslate(0);
    // } else {
    //   imageNav.slideTo(index);
    // }
    $that.find(".js-slider-nav .swiper-slide").removeClass("is-active");
    $that.find(".js-slider-nav .swiper-slide").eq(index).addClass("is-active");

    // console.log($that.find(".js-slider-nav .swiper-slide").eq(index));

    // $("is-is-active").

  })
  imageMain.on('slideChange', function() {
    $(".projectDetail-image-nav .swiper-slide").removeClass("is-active");
    $(".projectDetail-image-nav .swiper-slide").eq(imageMain.realIndex).addClass("is-active");
  })

})




// var sliderThumbs = new Swiper('.slider-thumbs', {
//       spaceBetween: 10,
//       slidesPerView: 4,
//       freeMode: true,
//       watchSlidesVisibility: true,
//       watchSlidesProgress: true,
//     });
//     var sliderTop = new Swiper('.slider-top', {
//       spaceBetween: 10,
//       navigation: {
//         nextEl: '.swiper-button-next',
//         prevEl: '.swiper-button-prev',
//       },
//       thumbs: {
//         swiper: sliderThumbs
//       }
//     });

//
// $(".top-mobileCategoryNav .swiper-slide").on('click', function(){
//   var index = topCategorySwiperNav.clickedIndex;
//   topCategorySwiper.slideTo(index);
//   // topCategorySwiperNav.slideTo(index);
//   // console.log(topCategorySwiperNav.clickedIndex);
//   // console.log(topCategorySwiperNav.progress);
//   // console.log(topCategorySwiperNav.translate);
//   if(index <= 2) {
//     topCategorySwiperNav.setTranslate(0);
//   } else {
//     topCategorySwiperNav.slideTo(index);
//   }
//   $(".top-mobileCategoryNav .swiper-slide").removeClass("is-active");
//   $(this).addClass("is-active");
//   setTimeout(function(){
//     underLine(index);
// 	},150);
//
//   // $("is-is-active").
//
// })
//
// topCategorySwiper.on('slideChangeTransitionEnd', function(){
//   var index = topCategorySwiper.activeIndex;
//   if(index <= 2) {
//     topCategorySwiperNav.setTranslate(0);
//   } else {
//     topCategorySwiperNav.slideTo(index);
//   }
//   $(".top-mobileCategoryNav .swiper-slide").removeClass("is-active");
//   $(".top-mobileCategoryNav .swiper-slide").eq(index).addClass("is-active");
//   setTimeout(function(){
//     underLine(index);
//     closePoint = $(".top-categorySlider").offset().top + $(".top-categorySlider").outerHeight();
// 	},150);
// });



// topCategorySwiper.init();
// topCategorySwiperNav.controller.control = topCategorySwiper;
// topCategorySwiper.controller.control = topCategorySwiperNav;
