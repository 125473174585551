import { TweenMax, Power2, TimelineLite } from "gsap/TweenMax";

const startButton = document.querySelector('.js-fukubikiStart');

if(startButton) {
  startButton.addEventListener("click", function (e) {
  
    e.preventDefault(); 
  
    const name = this.closest('form').getAttribute('name')
    // console.log(name)
      
    const main = document.querySelector('.fukubiki-hero-main img.-main')
    const lever = document.querySelector('.fukubiki-hero-main img.-lever')
    var styles = {
      animationName: 'roll',
      animationDuration: '0.8s',
      animationIterationCount: 1,
      animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)'
    }
    // console.log(openStyle)
    // $("#js-fullScreenMenu").fadeIn(250);
    for (const key in styles) {
      main.style[key] = styles[key];
      lever.style[key] = styles[key];
      // console.log(this.body.style[key as keyof typeof myStyle]);
    }
  
    setTimeout(function(){
      document.querySelector(`form[name="${name}"]`).submit();
      // window.location = url;
    }, 600);
    // document.querySelector('.fukubiki-hero-main').classList.add('is-roll');
  })
}


if($('.fukubiki-hero-result').length) {

  var tl_result = new TimelineMax();
  
  tl_result
    .to($(".fukubiki-hero-result img.-main, .fukubiki-hero-result img.-lever"), 0.8, {
      rotation: 0,
      ease: Back.easeOut.config(2),
      delay: 0,
      onComplete: compFunc
    })
    .to($(".fukubiki-hero-result__effect"), 0.8, {
      scale: 50,
      alpha: 0,
      delay: 0.1
    })
    .to($(".fukubiki-segment-body.js-result"), 0.3, {
      ease: Back.easeOut.config(4),
      alpha: 1,
      scale: 1,
      delay: -0.3
    })
    .to($(".c-button.js-result, p.js-result"), 0.3, {
      ease: Back.easeOut.config(4),
      alpha: 1,
      scale: 1
    })
  
  // .to($(".fukubiki-hero-result__ball"), 0.6, {
  //   x: "-110%",
  //   y: "-60%"
  // })
  // .to($(".fukubiki-hero-result__ball"), 0.3, {
  //   x: "-360%",
  //   y: "580%"
  // })
  
  
  
    function compFunc() {
  
      var $target = $(".fukubiki-hero-result__ball-target"), //ナビゲーションのカートボタン
        $window = $(window),
        targetPos, buttonPos;
    
      //カートに入れるボタンを押したら実行
      //「ナビゲーションのカートボタン」と「カートに入れるボタン」の位置を取得
      var $self = $(".fukubiki-hero-result__ball:not(.is-active)"),
        targetPos = $target.offset(),
        buttonPos = $self.offset();
      targetPos.top = targetPos.top - $window.scrollTop();
      buttonPos.top = buttonPos.top - $window.scrollTop();
    
      targetPos.left = targetPos.left + $target.width() / 2;
      buttonPos.left = buttonPos.left + $self.width() / 2;
    
      // console.log(targetPos)
      // console.log(buttonPos)
    
      var G = 10, //重力[px/s^2]
        v = 25, //初速[px/s]
        H = buttonPos.top - targetPos.top, //高度[px]
        S = targetPos.left - buttonPos.left; //距離[px]
    
      calcOrbit();
    
      /**
       * 放物線の軌道を計算
       */
      function calcOrbit() {
        //解の存在の有無判定に使う値
        var b = -1 * (2 * v * v * S) / (G * S * S),
          c = 1 + (2 * v * v * H) / (G * S * S);
    
        var D = b * b - 4 * c; //0以上なら解が存在
        console.log("D" + D)
        if (D >= 0) {
          //解が存在する場合はアニメーションを実行
    
          //放物線の最初の角度を算出
          var tanTheta0 = Math.atan((-b - Math.sqrt(D)) / 2),
            tanTheta1 = Math.atan((-b + Math.sqrt(D)) / 2),
            theta = Math.max(tanTheta0, tanTheta1);
    
          //アニメーションを実行
          startAnimation();
        } else {
          //解が存在しない場合は、初速を追加して放物線の軌道を再計算
          v++;
          calcOrbit();
        }
    
        /**
         * アニメーションを実行
         */
        function startAnimation() {
          //アニメーションさせるボールを設置
          // $("body").append('<div class="ball" />');
          var $ball = $(".fukubiki-hero-result__ball").last();
          $ball.css({
            "opacity": "1",
            "right": buttonPos.left + "px",
            "top": buttonPos.top - 50 + "px",
            "z-index": 10000
          });
          $self
            .addClass("is-active")
          // .text("処理中");
    
    
    
          //アニメーション実装
          var startTime = performance.now();
          requestAnimationFrame(loop);
          function loop(nowTime) {
            var t = (nowTime - startTime) / 75, //時間を取得
              x = v * Math.cos(theta) * t, //横方向の位置を算出
              y = Math.tan(theta) * x - (G / (2 * v * v * Math.cos(theta) * Math.cos(theta))) * x * x; //縦方向の位置を算出
            //算出した値を基にボールの位置を移動
            $ball.css({
              "right": Math.round(buttonPos.left + x) + "px",
              "top": Math.round(buttonPos.top - y) - 50 + "px"
            });
            //ボールがカートボタンに到着するまでアニメーションを実行
            if (buttonPos.left - x + 50 > targetPos.left) {
              requestAnimationFrame(loop);
            } else {
              //アニメーションの終了処理
              $ball.remove();
              $target.children()
                .addClass("is-active");
              // $self.text("カートに入れました");
              var timer = setTimeout(function () {
                clearInterval(timer);
                $target.children().removeClass("is-active");
              }, 1000);
            }
          }
        }
      }
    }

}
